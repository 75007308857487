import React from 'react';

import type { FrapiTeaserItemSized } from '../../../../types/frapi-types.js';

import RecommendationCard from './RecommendationCard.js';

interface RecommendationListProps {
  recommendations?: FrapiTeaserItemSized[];
}

const RecommendationList: React.FC<RecommendationListProps> = ({
  recommendations,
}) => {
  if (!recommendations?.length) return null;

  const labelText = (): string =>
    `Eller sjekk ut ${recommendations.length > 1 ? 'disse sakene' : 'denne saken'}:`;

  return (
    <div className="recommendations">
      <p className="recommendations__label">{labelText()}</p>
      <ul className="recommendations__list">
        {recommendations.map((rec, idx) => (
          <li key={rec.id}>
            <RecommendationCard idx={idx} teaser={rec} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecommendationList;
