import type { PipelineResponse } from './types.js';

export async function fetchPipeline(
  message: string,
): Promise<PipelineResponse[]> {
  const url = `https://hey.aftenposten.no/hei/pipeline`;

  const payload = {
    user_message: message,
    pipeline_names: ['vink_pipeline'],
  };

  const headers = { 'Content-Type': 'application/json' };
  const results: PipelineResponse[] = [];
  let partialChunk: string = ''; // Stores unprocessed stream fragments

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
      credentials: 'include',
    });

    if (!response.body) {
      throw new Error('No response body');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false;

    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;

      if (value) {
        partialChunk += decoder.decode(value, { stream: true });
        const lines = partialChunk.split('\n');

        while (lines.length > 1) {
          const rawLine = lines.shift()?.trim();

          if (rawLine) {
            try {
              const result: PipelineResponse = JSON.parse(rawLine);

              if (['chat_history', 'sources'].includes(result.name)) {
                results.push(result);
              }
            } catch (_error) {
              break;
            }
          }
        }

        partialChunk = lines.join('\n'); // Preserve unprocessed parts
      }
    }

    return results;
  } catch (_error) {
    return [];
  }
}
