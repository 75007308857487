import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react';

import type { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';
import type { NewsMediaPulseTrackerInterface } from '@schibsted/pulse-news-media';
import type {
  PulseContextProviderProps,
  PulseContextValue,
  TrackEvent,
  RegisterPageLeaveTracking,
  SDKConfigInput,
} from './types/index.js';
import type { PulseExperiment } from '../../../../public-src/core/js/pulse/pulse-util.js';

import { handleProductTag } from './utils/index.js';
import { defaultPulseConfig } from './config.js';
import { provideFakePulseAutotracker } from './pulse-autotracker-fake.server.js';
import { getNewsMediaPulseTrackerFromClass } from '../../../../public-src/core/js/pulse/pulse-news-media.js';
import {
  createPulseObject,
  createPulseTarget,
  getPulseMeta,
} from '../../../../public-src/core/js/pulse/pulse-util.js';
import { getTeaserMetrics } from './utils/metrics.js';

export type AltEventHandler = NonNullable<SDKConfigInput['altEventHandler']>;

const defaultNewsMediaPulse: NewsMediaPulseTrackerInterface = {
  pulseTracker: {} as PulseTracker,
  trackViewArticle: () => Promise.resolve(null),
  trackViewSalesPoster: () => Promise.resolve(null),
  trackViewFrontpage: () => Promise.resolve(null),
  trackViewListing: () => Promise.resolve(null),
  trackViewPage: () => Promise.resolve(null),
  trackViewLoginPoster: () => Promise.resolve(null),
  addPageLeaveTracking: () => () => {},
  updatePageLeaveTracking: () => {},
  updateBaseEvent: () => {},
  removePageLeaveTracking: () => {},
  trackActivePageLeave: () => {},
  trackPageLeave: () => Promise.resolve(),
  trackViewUIElement: () => Promise.resolve(),
  trackClickUIElement: () => Promise.resolve(),
  addExperiments: () => {},
  addUIElementVisibilityTracking: () => () => {},
};

const PulseContext = createContext<PulseContextValue | null>(null);

const PulseContextProvider: React.FC<PulseContextProviderProps> = ({
  config,
  children,
  experiments = [],
}) => {
  const [pulse, setPulse] = useState<PulseTracker>();
  const [newsMediaPulse, setNewsMediaPulse] =
    useState<NewsMediaPulseTrackerInterface>(defaultNewsMediaPulse);

  const { providerId, targetNewsroom } = config;

  useEffect(() => {
    if (!pulse) {
      const initializePulseTracker = async () => {
        const newsMediaPulse = await getNewsMediaPulseTrackerFromClass();

        const { pulseTracker } = newsMediaPulse;

        setNewsMediaPulse(newsMediaPulse);

        handleProductTag(pulseTracker, config);

        setPulse(pulseTracker);

        provideFakePulseAutotracker(pulseTracker);

        const baseExperiments = window.PULSE_EXPERIMENTS || [];

        const isFrontpage = () => window.location.pathname === '/';
        if (isFrontpage()) {
          const { experiment } = window.CURATE_CONFIG || {};
          if (experiment) {
            baseExperiments.push(experiment);
          }
        }
        // Add experiments right away since we can't know if view frontpage
        // fires before the other useEffect for updating experiments
        pulseTracker.update({
          experiments: baseExperiments.concat(experiments),
        });
      };

      initializePulseTracker();
    }
  }, [pulse, config, providerId, experiments]);

  const trackEvent: TrackEvent = useCallback(
    (input, options = {}) => {
      if (pulse) {
        return pulse.track('trackerEvent', input, options);
      }

      return Promise.resolve(null);
    },
    [pulse],
  );

  const registerPageLeaveTracking: RegisterPageLeaveTracking = useCallback(
    ({ objectElement, pageElement = document.querySelector('body') }) => {
      if (pageElement) {
        newsMediaPulse.addPageLeaveTracking(objectElement, pageElement);
      }
    },
    [newsMediaPulse],
  );

  const getEnvironmentId = useCallback(() => {
    if (pulse) {
      return pulse.getEnvironmentId();
    }

    return Promise.resolve(null);
  }, [pulse]);

  const {
    trackViewArticle,
    trackViewSalesPoster,
    trackViewPage,
    trackViewFrontpage,
    trackViewListing,
    addUIElementVisibilityTracking,
    trackClickUIElement,
  } = newsMediaPulse;

  const registerViewElementTracking = useCallback(() => {
    addUIElementVisibilityTracking('[data-pulse-entity-id]', (element) => {
      const pulseMeta = getPulseMeta(element);
      const target = createPulseTarget(pulseMeta, providerId, targetNewsroom);
      const object = createPulseObject(pulseMeta);

      const { variant } = window.CURATE_CONFIG || {};

      const metrics = getTeaserMetrics(variant, pulseMeta);
      const teaserExperiments = [...(pulseMeta.experiments || [])];

      return {
        object,
        target,
        metrics,
        experiments: teaserExperiments,
      };
    });
  }, [addUIElementVisibilityTracking, providerId, targetNewsroom]);

  return (
    <PulseContext.Provider
      value={{
        trackEvent,
        registerViewElementTracking,
        registerPageLeaveTracking,
        getEnvironmentId,
        trackViewArticle,
        trackViewSalesPoster,
        trackViewFrontpage,
        trackViewListing,
        trackViewPage,
        trackClickUIElement,
      }}
    >
      {children}
    </PulseContext.Provider>
  );
};

const usePulseContext = (): PulseContextValue => {
  const context = useContext(PulseContext);

  if (!context) {
    throw new Error('No Pulse context provided');
  }

  return context;
};

type WithPulseProps = {
  experiments?: PulseExperiment[];
};

const withPulse = <P extends Record<string, unknown>>(
  Component: React.FC<P>,
  pulseConfig = defaultPulseConfig,
): React.FC<Omit<P, keyof WithPulseProps> & WithPulseProps> => {
  const Hoc = (props: Omit<P, keyof WithPulseProps> & WithPulseProps) => {
    const { experiments, ...rest } = props;

    return (
      <PulseContextProvider config={pulseConfig} experiments={experiments}>
        <Component {...(rest as P)} />
      </PulseContextProvider>
    );
  };

  Hoc.displayName = `withPulse<${Component.displayName}>`;

  return Hoc;
};

export { usePulseContext, withPulse };
