import React from 'react';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';

interface ChatHeaderProps {
  hasUserMessage: boolean;
  resetChat: () => void;
}

const COMPASS_ICON_URL = `${frontendConfig.cdnUrlInternal}/vink/images/icons/compass.svg`;
const CLOSE_ICON_URL = `${frontendConfig.cdnUrlInternal}/vink/images/icons/cross_bold.svg`;

const ChatHeader: React.FC<ChatHeaderProps> = ({
  hasUserMessage,
  resetChat,
}) => {
  return (
    <div className="vinkomat-header">
      <div className="vinkomat-header__content">
        <img
          className="content-icon"
          src={COMPASS_ICON_URL}
          alt="Vink-O-mat navigasjonsikon"
        />
        <h3 className="title">Vinkomat</h3>
      </div>
      {hasUserMessage && (
        <button
          className="vinkomat-header__close-button"
          aria-label="Fjern tekst og gå tilbake til start"
          onClick={resetChat}
        >
          <img
            className="close-button-icon"
            src={CLOSE_ICON_URL}
            alt="Lukk chat ikon"
          />
        </button>
      )}
    </div>
  );
};

export default ChatHeader;
