import { useEffect } from 'react';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import {
  createPulseObject,
  createPulseTarget,
  getPulseMeta,
} from '../../../../../public-src/core/js/pulse/pulse-util.js';

const isHTMLElement = (target: Element): target is HTMLElement =>
  target instanceof HTMLElement;

export const getPulseData = (element: HTMLElement) => {
  const pulseMeta = getPulseMeta(element);
  const target = createPulseTarget(pulseMeta, 'aftenposten');

  const object = createPulseObject(pulseMeta);

  return { object, target };
};

/**
 * A hook for Pulse that tracks the visibility of child elements within a scrollable container.
 * When a child element becomes visible, a Pulse impression event is sent, and the element is no longer observed.
 *
 * @template TElement The type of the HTML element used as the scrollable container.
 * @param {React.RefObject<TElement>} scrollContainerRef - A React ref object pointing to the scrollable container.
 * @param {number} [threshold=1] - A threshold value indicating how much of the child element must be visible before sending an impression.
 *                                  A value of 1 means the entire element must be visible.
 *
 * @example
 * const containerRef = useRef<HTMLDivElement>(null);
 * useScrollTracker(containerRef, 0.5);
 *
 * return (
 *   <div ref={containerRef} style={{ overflowY: 'scroll', maxHeight: '400px' }}>
 *     <div>Item 1</div>
 *     <div>Item 2</div>
 *     <div>Item 3</div>
 *   </div>
 * );
 */
export const useScrollTracker = <TElement extends HTMLElement>(
  scrollContainerRef: React.RefObject<TElement>,
  threshold: number = 1,
): void => {
  const { trackEvent } = usePulseContext();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && isHTMLElement(entry.target)) {
            const pulseData = getPulseData(entry.target);

            if (pulseData) {
              const { object, target } = pulseData;
              trackEvent({ object, target });
            }

            observer.unobserve(entry.target);
          }
        });
      },
      { root: null, threshold },
    );

    const container = scrollContainerRef.current;
    if (!container) return () => null;

    const children = Array.from(container.children);
    children.forEach((child) => observer.observe(child));

    return () => {
      observer.disconnect();
    };
  }, [getPulseData, trackEvent]);
};
