import React from 'react';

interface UserSearchedProps {
  search: string;
}

const UserSearched: React.FC<UserSearchedProps> = ({ search }) => (
  <div className="searching__searched">
    <p className="search-result">{search}</p>
  </div>
);

export default UserSearched;
