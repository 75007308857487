import React from 'react';

const BotTypingIndicator: React.FC = () => {
  return (
    <div className="loading">
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
    </div>
  );
};

export default BotTypingIndicator;
