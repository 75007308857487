import type { PulseContextProviderConfig } from '../../core/mandatory-integrations/pulse/types/index.js';

import { withPulse } from '../../core/mandatory-integrations/pulse/PulseProvider.js';

export const withByfunnPulse = <P extends Record<string, unknown>>(
  Component: React.FC<P>,
  pulseConfig?: Partial<PulseContextProviderConfig>,
) =>
  withPulse(Component, {
    providerId: 'aftenposten',
    productTag: 'vink',
    targetNewsroom: 'vink',
    ...pulseConfig,
  });
