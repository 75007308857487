import React from 'react';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';
import BotTypingIndicator from './BotTypingIndicator.js';
import UserSearched from './UserSearched.js';

interface WaitingForResultsProps {
  userMessage?: string;
}

const WaitingForResults: React.FC<WaitingForResultsProps> = ({
  userMessage,
}) => {
  return (
    <div className="searching" role="status" aria-label="Laster innhold">
      {userMessage && <UserSearched search={userMessage} />}
      <img
        className="searching__icon"
        src={`${frontendConfig.cdnUrlInternal}/vink/images/illustrations/wine-bottle-man.svg`}
        alt="Man with wine bottle"
      />
      <p className="searching__info">Vi finner forslag til deg</p>
      <BotTypingIndicator />
    </div>
  );
};

export default WaitingForResults;
