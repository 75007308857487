import React from 'react';

import type {
  ByfunnFront,
  FrapiBundleItem,
  FrapiTeaserItem,
  Widget,
} from '../../../types/frapi-types.js';

import GuidesWidget from './GuidesWidget.js';
import RestaurantsWidget from './RestaurantsWidget.js';
import ChatWidget from '../../components/vinkomat/index.js';
import { ReelsSlider } from '../../../../aftenposten/widgets/Reels/components/ReelsSlider.js';

const hasTeaserItems = (items: (FrapiBundleItem | FrapiTeaserItem)[]) =>
  items.some((item) => item.type === 'teaser' && item.src === 'b2b');

export function renderWidget(
  w: Widget,
  isMobile: boolean,
  index: number,
  response: ByfunnFront,
  enableChatbot: boolean,
  isLoggedIn: boolean,
): React.ReactNode {
  if (w.component === 'vink-bot' && isLoggedIn && enableChatbot)
    return <ChatWidget />;
  if (
    w.component === 'guides' &&
    response.widgetGuides &&
    hasTeaserItems(response.widgetGuides.items)
  ) {
    return (
      <GuidesWidget
        feed={response.feed}
        data={response.widgetGuides}
        index={index}
        isMobile={isMobile}
      />
    );
  }
  if (
    w.component === 'restaurants' &&
    response.restaurantGuides &&
    'items' in response.restaurantGuides
  ) {
    return (
      <RestaurantsWidget data={response.restaurantGuides} isMobile={isMobile} />
    );
  }

  const reels = response.playlist?.resolved;
  if (w.component === 'reels' && reels && reels?.length >= 4) {
    const title = response.playlist?.name || 'Videoer fra Vink';

    return (
      <div className="reels-container">
        <ReelsSlider
          title={title}
          playlistId={'ZZqeAaZlMfPAADWyHudk9QKy'}
          reels={reels.slice(0, 10)}
          url={'https://vink.aftenposten.no'}
          brand="vink"
        />
      </div>
    );
  }

  if (w.component.includes('braze')) {
    return <div id={w.component} className="braze content-card"></div>;
  }

  return null;
}
