import type { PulseAttributes } from '../utils/index.js';
import type { AccessLevel, Target } from '@schibsted/pulse-news-media';

export const createTargetObject = (
  pulseAttributes: PulseAttributes,
  newsroom: string,
): Target => {
  const {
    entityId,
    entityTargetName,
    entityTargetUrl,
    entityAccessLevel,
    entityAccessLevelName,
  } = pulseAttributes;

  return {
    id: entityId || '',
    type: 'Article',
    name: entityTargetName || '',
    url: entityTargetUrl || '',
    accessLevel: entityAccessLevel as AccessLevel,
    accessLevelName: entityAccessLevelName,
    newsroom,
  };
};
