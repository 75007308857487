import React, { useState } from 'react';
import axios from 'axios';

import type { ErrorType, MessageType } from './types.js';

import ChatHeader from './chatBotComponents/ChatHeader.js';
import ChatIntro from './chatBotComponents/ChatIntro.js';
import ChatBody from './chatBotComponents/ChatBody.js';
import ChatSubmitButton from './chatBotComponents/ChatSubmitButton.js';
import {
  extractChatHistory,
  extractSourceIds,
  processBotResponse,
} from './utils.js';
import { fetchPipeline } from './api.js';
import { useTrackClick } from './hooks.js';
import ChatFooter from './chatBotComponents/ChatFooter.js';

const Chat: React.FC = () => {
  const [userMessage, setUserMessage] = useState<string | null>(null);
  const [botMessage, setBotMessage] = useState<MessageType | null>(null);
  const [inputText, setInputText] = useState('');
  const [isWaitingForResults, setIsWaitingForResults] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const trackClick = useTrackClick();

  const handleSendMessage = async (message: string, predefinedTag = false) => {
    setUserMessage(message);

    trackClick({
      id: 'vink-o-mat-message',
      name: `${predefinedTag ? 'Predefined message' : 'Message'} sent: ${message}`,
    });

    await fetchBotResponse(message);
  };

  const fetchBotResponse = async (message: string) => {
    setIsWaitingForResults(true);
    const controller = new AbortController();
    setAbortController(controller);

    try {
      const response = await fetchPipeline(message);

      const chatHistory = extractChatHistory(response);
      const botAnswer =
        chatHistory.find((chat) => chat.role === 'assistant')?.content ||
        'Beklager, det har skjedd en feil, prøv igjen om litt.';

      const sourceIds = extractSourceIds(response);

      const formattedResponse = { botAnswer, sourceIds };
      await processBotResponse(formattedResponse, setBotMessage);
    } catch (error) {
      if (!axios.isCancel(error)) {
        processError(error);
      }
    } finally {
      setIsWaitingForResults(false);
      setAbortController(null);
    }
  };
  const processError = (error: unknown) => {
    const defaultErrorMessage =
      'Beklager, det har skjedd en feil, prøv igjen om litt.';
    const notFoundErrorMessage =
      'Beklager, jeg fant ingen artikler som passer til det du spurte om... Gjerne prøv et annet søk!';

    const errorMessage =
      (error as ErrorType).response?.status === 404
        ? notFoundErrorMessage
        : defaultErrorMessage;

    setBotMessage({ text: errorMessage });
  };

  const resetChat = () => {
    if (abortController) {
      abortController.abort();
    }
    setInputText('');
    setUserMessage(null);
    setBotMessage(null);
    setIsWaitingForResults(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputText(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') handleSendMessage(inputText);
  };

  const shouldRenderFooter = () => !!userMessage && !isWaitingForResults;

  const shouldRenderChatSubmitButton = () =>
    !isWaitingForResults && !userMessage;

  return (
    <section className="chat">
      <div className="vinkomat" data-pulse-entity-id="vink-o-mat">
        <ChatHeader hasUserMessage={!!userMessage} resetChat={resetChat} />
        <div className="vinkomat__chat-window">
          {userMessage ? (
            <ChatBody
              userMessage={userMessage}
              botMessage={botMessage}
              isWaitingForResults={isWaitingForResults}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
              inputText={inputText}
              sendMessage={handleSendMessage}
              buttonLabel="Nytt svar"
            />
          ) : (
            <ChatIntro
              inputText={inputText}
              setInputText={setInputText}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
            />
          )}
        </div>
        {shouldRenderChatSubmitButton() && (
          <ChatSubmitButton
            buttonLabel="Søk"
            primaryButton={true}
            inputText={inputText}
            sendMessage={handleSendMessage}
            showIcon={true}
          />
        )}
        {shouldRenderFooter() && <ChatFooter />}
      </div>
    </section>
  );
};

export default Chat;
