import { useEffect } from 'react';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';

interface ClickTrackOptions {
  id: string;
  name: string;
  elementType?: string;
}

export const useTrackClick = () => {
  const { trackClickUIElement } = usePulseContext();

  return ({ id, name, elementType = 'Widget' }: ClickTrackOptions) => {
    trackClickUIElement({
      object: { id, name, elementType },
    });
  };
};

export const useAutosizeTextArea = (
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
  value: string,
) => {
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      // Reset height so that shrinking works properly.
      textArea.style.height = '28px';
      const scrollHeight = textArea.scrollHeight;
      // Then set the height to the scrollHeight.
      textArea.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);
};
