import React from 'react';

interface DisclaimerProps {
  text: string;
}
const Disclaimer: React.FC<DisclaimerProps> = ({ text }) => {
  return <p className="disclaimer">{text}</p>;
};

export default Disclaimer;
