import type { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';

import { console } from '../../system/console/console.server.js';

const realPulseAutotrackerExists = !!window.pulse;

if (typeof window === 'object') {
  if (!realPulseAutotrackerExists) {
    window.pulse = function (...args) {
      (window.pulse.q = window.pulse.q || []).push(args);
    };
  }
}

export function provideFakePulseAutotracker(trackerInstance: PulseTracker) {
  if (realPulseAutotrackerExists) {
    return;
  }

  const pastInvocationsQueue = window?.pulse.q;

  window.pulse = (...args: unknown[]) => {
    if (args.length === 0) {
      return;
    }

    if (typeof args[0] === 'function') {
      return args[0](trackerInstance);
    }

    if (typeof args[0] === 'string') {
      if (args[0] === 'init') {
        console.warn('Fake pulse autotracker initialized:', args);
      }

      if (typeof args[1] === 'function') {
        // always return a default tracker
        args[1](trackerInstance);
      }
    }
  };

  if (pastInvocationsQueue && pastInvocationsQueue.length) {
    pastInvocationsQueue.forEach((args) => window.pulse.apply(null, args));
  }
}
