import axios from 'axios';

import type { Tag } from '../../../../core/data-sources/frapi/data-types.js';
import type {
  BotResponse,
  ChatHistory,
  MessageType,
  PipelineResponse,
  SourceData,
  TeasersResponse,
} from './types.js';

import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

const teasersUrl =
  frontendConfig.serverRuntime === 'convox-production'
    ? 'https://vink.aftenposten.no/api/teasers/'
    : '/api/teasers/';

export const getLocation = (tags: Tag[]) => {
  const tagsArray = tags.filter((tag) => tag.title.startsWith('Vink/område'));
  const tagTitle = tagsArray[0]?.title.split('/')[2];

  return tagsArray.length > 0 ? tagTitle : '';
};

export const getTeasers = async (id: string) => {
  const article = await axios.get(`${teasersUrl}${id}`);

  return article.data;
};

export const processBotResponse = async (
  response: BotResponse,
  setBotMessage: (message: MessageType) => void,
) => {
  const { botAnswer, sourceIds } = response;
  const sourcesString = sourceIds.length ? sourceIds.join(',') : '';
  const newMessage: MessageType = { text: botAnswer };

  if (sourcesString) {
    const teasers: TeasersResponse = await getTeasers(sourcesString);

    newMessage.recommendations = Object.values(teasers).map((teaser) => ({
      ...teaser,
      hideSaveButton: true,
    }));
  }
  setBotMessage(newMessage);
};

// Type Guard for identifying valid ChatHistory objects
export const isChatHistory = (item: unknown): item is ChatHistory =>
  typeof item === 'object' &&
  item !== null &&
  'role' in item &&
  'content' in item &&
  typeof (item as ChatHistory).role === 'string' &&
  typeof (item as ChatHistory).content === 'string';

export const extractChatHistory = (
  response: PipelineResponse[],
): ChatHistory[] => {
  const chatHistory = response.find((r) => r.name === 'chat_history')?.data;

  return Array.isArray(chatHistory) ? chatHistory.filter(isChatHistory) : [];
};

export const extractSourceIds = (response: PipelineResponse[]): string[] => {
  const sourceData = response.find((r) => r.name === 'sources')?.data;

  return Array.isArray(sourceData) && sourceData.every((s) => 'article_id' in s)
    ? (sourceData as SourceData[]).map((s) => s.article_id)
    : [];
};
