import React, { useRef } from 'react';

import type { GeneralChatComponentProps } from '../types.js';

import { useAutosizeTextArea, useTrackClick } from '../hooks.js';
import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';
import Disclaimer from './Disclaimer.js';

const PENCIL_ICON_URL = `${frontendConfig.cdnUrlInternal}/vink/images/icons/edit_pen.svg`;
const MAX_LENGTH = 240;

const ChatInput: React.FC<GeneralChatComponentProps> = ({
  inputText,
  handleInputChange,
  handleKeyDown,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef, inputText);

  const trackClick = useTrackClick();

  const handleOnClick = () => {
    trackClick({
      id: 'vink-o-mat-input',
      name: 'Input field clicked',
    });
  };

  return (
    <div>
      <div className="input-wrapper">
        <div className="input-wrapper__container">
          <textarea
            ref={textAreaRef}
            className="text-field"
            placeholder="Skriv inn ønske selv"
            value={inputText}
            onClick={handleOnClick}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            maxLength={240}
          />
          <img
            className="icon-img pencil-icon"
            src={PENCIL_ICON_URL}
            alt="Skriv"
          />
        </div>
      </div>
      {inputText.length >= MAX_LENGTH && (
        <Disclaimer text="Tekstboksen kan maksimalt innneholde  240 karakterer." />
      )}
    </div>
  );
};

export default ChatInput;
