import type { WidgetConfig } from '../../../types/frapi-types.js';

export const desktopWidgetConfig: WidgetConfig[] = [
  { widget: 'vink-bot', position: 1 },
  { widget: 'reels', position: 2 },
  { widget: 'braze-content-card-pos-1', position: 4 },
  { widget: 'guides', position: 3 },
  // { widget: 'restaurants', position: 3 },
  { widget: 'braze-content-card-pos-2', position: 5 },
  { widget: 'braze-content-card-pos-3', position: 8 },
];

export const mobileWidgetConfig: WidgetConfig[] = [
  { widget: 'vink-bot', position: 1 },
  { widget: 'reels', position: 2 },
  { widget: 'braze-content-card-pos-1', position: 4 },
  { widget: 'guides', position: 3 },
  { widget: 'braze-content-card-pos-2', position: 6 },
  { widget: 'braze-content-card-pos-3', position: 8 },
];
