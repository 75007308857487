import React from 'react';
import { clsx } from 'clsx';

import type { SubmitButtonProps } from '../types.js';

import { useTrackClick } from '../hooks.js';
import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';

const SEARCH_ICON_URL = `${frontendConfig.cdnUrlInternal}/vink/images/icons/search.svg`;

const ChatSubmitButton: React.FC<SubmitButtonProps> = ({
  inputText,
  sendMessage,
  buttonLabel,
  primaryButton,
  showIcon = false,
}) => {
  const trackClick = useTrackClick();
  const handleSubmit = () => {
    sendMessage(inputText);
    trackClick({
      id: 'vink-o-mat-input',
      name: 'Submit search button clicked',
    });
  };

  const classNames = clsx(
    'send-button',
    primaryButton ? 'send-button__primary' : 'send-button__secondary',
  );

  return (
    <button
      disabled={!inputText}
      className={classNames}
      aria-label="Send melding til Vinkomat"
      onClick={handleSubmit}
    >
      {buttonLabel}
      {showIcon && (
        <img className="send-icon-img" src={SEARCH_ICON_URL} alt="Søk" />
      )}
    </button>
  );
};

export default ChatSubmitButton;
