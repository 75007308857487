import React, { useEffect, useRef } from 'react';
import { init } from '@schibsted/data-controller';

import type { DataControllerSdkOptions } from '@schibsted/data-controller';

const defaultOptions: Partial<DataControllerSdkOptions> = {
  language: 'no',
  layout: 'footer',
  hideAfter: 8000,
  minimizeText: true,
};

export const DataController: React.FC<DataControllerSdkOptions> = (
  dataControllerOptions,
) => {
  const dataControllerPermanent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = dataControllerPermanent.current;
    if (!current) return () => {};

    const options = {
      ...defaultOptions,
      ...dataControllerOptions,
    };

    const sdkInstance = init(options);

    sdkInstance.render(current, {
      permanent: true,
    });

    const dataControllerSticky = document.createElement('div');
    dataControllerSticky.id = 'data-controller-sticky';

    sdkInstance.render(dataControllerSticky, { permanent: false });
    document.body.appendChild(dataControllerSticky);

    return () => {
      dataControllerSticky.remove();
      if (current) current.replaceChildren();
    };
  }, [dataControllerOptions]);

  return (
    <div id="data-controller-permanent" ref={dataControllerPermanent}></div>
  );
};
