import React from 'react';

import type { Recommendation } from '../types.js';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';
import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import Image from '../../teasers/teaserComponents/Image.js';
import TeaserKicker from '../../teasers/teaserComponents/TeaserKicker.js';

type RecommendationCardProps = Recommendation & { idx: number };

const VINK_URL =
  frontendConfig.serverRuntime === 'convox-production'
    ? 'https://vink.aftenposten.no/artikkel/'
    : '/artikkel/';

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  idx,
  teaser,
}) => {
  const { id, image, title } = teaser;

  return (
    <LinkWithPulse
      href={`${VINK_URL}${id}`}
      className="recommendation-card teaser"
      id="vink-o-mat:article"
      name={`Article suggestion #${idx + 1} click: ${id}`}
    >
      <figure className="recommendation-card__image">
        {image && (
          <Image imageAsset={image} title={title} width={360} height={360} />
        )}
      </figure>
      <div className="recommendation-card__body">
        <TeaserKicker {...teaser} />
        <h4 className="recommendation-card__title">
          {teaser.title ?? 'Restaurantguide'}
        </h4>
      </div>
    </LinkWithPulse>
  );
};

export default RecommendationCard;
