import React from 'react';

import type {
  MessageType,
  GeneralChatComponentProps,
  SubmitButtonProps,
} from '../types.js';

import Message from './Message.js';
import WaitingForResults from './WaitingForResults.js';
import ChatInput from './ChatInput.js';

interface ChatBodyProps extends GeneralChatComponentProps, SubmitButtonProps {
  userMessage: string | null;
  botMessage: MessageType | null;
  isWaitingForResults: boolean;
}

const ChatBody: React.FC<ChatBodyProps> = ({
  userMessage,
  botMessage,
  isWaitingForResults,
  inputText,
  buttonLabel,
  handleInputChange,
  handleKeyDown,
  sendMessage,
}) => {
  if (!userMessage) return null;

  return (
    <>
      {isWaitingForResults ? (
        <WaitingForResults userMessage={userMessage} />
      ) : (
        botMessage && (
          <>
            <ChatInput
              inputText={inputText}
              handleInputChange={handleInputChange}
              handleKeyDown={handleKeyDown}
            />
            <Message
              inputText={inputText}
              buttonLabel={buttonLabel}
              sendMessage={sendMessage}
              text={botMessage.text}
              recommendations={botMessage.recommendations}
            />
          </>
        )
      )}
    </>
  );
};

export default ChatBody;
