import React, { useState, useEffect } from 'react';
import { clsx } from 'clsx';

import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';

const SUGGESTIONS = [
  'Solrik utepils',
  'Kaffebar som løfter humøret',
  'Indisk perle',
  'God stemning, liten pris',
  'Perfekt for daten',
  'Diggeste pizzaen',
];
const TAG_PLUSS_ICON_URL = `${frontendConfig.cdnUrlInternal}/vink/images/icons/tag_pluss.svg`;
const normalize = (text: string) => text.toLowerCase();

interface TagSuggestionProps {
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  inputText: string;
}

const TagSuggestion: React.FC<TagSuggestionProps> = ({
  setInputText,
  inputText,
}) => {
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);

  const normalizedInput = normalize(inputText);

  useEffect(() => {
    if (selectedPrompt && normalizedInput !== normalize(selectedPrompt)) {
      setSelectedPrompt(null);
    }
  }, [selectedPrompt, normalizedInput]);

  const handleOnClick = (suggestion: string) => {
    setInputText((prevInput) =>
      normalize(prevInput) === normalize(suggestion) ? '' : suggestion,
    );

    setSelectedPrompt((prevPrompt) =>
      normalize(prevPrompt ?? '') === normalize(suggestion) ? null : suggestion,
    );
  };

  return (
    <div className="suggestions">
      {SUGGESTIONS.map((suggestion) => {
        const isSelected = normalize(suggestion) === normalizedInput;

        return (
          <button
            aria-label="Legg til forslaget i søkefeltet"
            key={suggestion}
            className={clsx('suggestions__buttons', isSelected && 'selected')}
            onClick={() => handleOnClick(suggestion)}
          >
            <img
              className="icon-img tag-pluss"
              src={TAG_PLUSS_ICON_URL}
              alt="ikon"
            />
            {suggestion}
          </button>
        );
      })}
    </div>
  );
};

export default TagSuggestion;
