import React from 'react';

import { useTrackClick } from '../hooks.js';

const EMAIL_LINK = 'mailto:vink@aftenposten.no';

const ContactInfo: React.FC = () => {
  const trackClick = useTrackClick();

  const handleClick = () => {
    trackClick({
      id: 'vink-o-mat-email',
      name: 'Contact us email clicked',
    });
  };

  return (
    <div className="contact-info">
      <span className="contact-info__text">
        Vil du sende oss tips? Kontakt oss på{' '}
        <a
          className="contact-info__link"
          href={EMAIL_LINK}
          onClick={handleClick}
        >
          vink@aftenposten.no
        </a>
      </span>
    </div>
  );
};

export default ContactInfo;
