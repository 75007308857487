import React, { createContext, useContext, useState } from 'react';

import type { SaveList } from '../../../types/save-types.js';

import { useGetSaved } from '../../hooks/useGetSaved.js';
import { makeHumanFriendlyString } from '../../../helpers/make-human-friendly-string.js';
import { userDataStoreApi } from '../../../../../public-src/core/js/user-data-store/api.server.js';

type ListsContextType = {
  savedItems: SaveList[];
  statusMessage: string;
  saveItem: (item: SaveList, onOverlayAction: () => void) => void;
  deleteItems: (list: SaveList, idToDelete: string[]) => void;
  deleteList: (name: string) => void;
  editList: (name: string, newListName: string) => void;
  isLoading: boolean;
  hasError: boolean;
};

const ListsContext = createContext<ListsContextType | null>(null);

export const ListsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [savedItems, setSavedItems, isLoading, hasError] = useGetSaved();
  const [statusMessage, setStatusMessage] = useState('');

  const updateSavedItems = async (updatedItems: SaveList[]) => {
    try {
      setSavedItems(updatedItems);
      await userDataStoreApi.setUserData('vink-lists', updatedItems);
    } catch (_error) {
      setStatusMessage('Noe gikk galt');
    }
  };

  const saveItem = async (item: SaveList, onOverlayAction: () => void) => {
    if (hasError) {
      setStatusMessage('Noe gikk galt');

      return;
    }

    const findExistingList = (l: SaveList) => l.name === item.name;
    const listExists = savedItems.some(findExistingList);
    try {
      let statusMessage = '';
      let updatedLists = [];

      if (listExists) {
        updatedLists = savedItems.map((l) => (l.name === item.name ? item : l));
        statusMessage = `Lagret i ${item.name}`;
      } else {
        updatedLists = savedItems.concat(item);
        statusMessage = `${item.name} er opprettet`;
      }

      await updateSavedItems(updatedLists);
      setStatusMessage(statusMessage);
      setTimeout(function () {
        onOverlayAction();
      }, 1000);
    } catch (_error) {
      setStatusMessage(
        listExists
          ? 'Saken ble dessverre ikke lagret. Prøv igjen!'
          : 'Listen ble dessverre ikke opprettet. Prøv igjen!',
      );
    }
  };

  const deleteItems = async (list: SaveList, idsToDelete: string[]) => {
    try {
      const findExistingList = (l: SaveList) => l.name === list.name;
      const listExists = savedItems.some(findExistingList);
      if (listExists) {
        const content = list.content;
        const newContent = content.filter((i) => !idsToDelete.includes(i.id));
        const deletedItems = content
          .filter((i) => idsToDelete.includes(i.id))
          .map((i) => i.title);
        const newLists = savedItems.map((l) => {
          if (l.name === list.name) {
            return { ...l, content: newContent };
          } else {
            return l;
          }
        });
        await updateSavedItems(newLists);
        setStatusMessage(`${makeHumanFriendlyString(deletedItems)} er slettet`);
      }
    } catch (_error) {
      setStatusMessage('Artiklene ble dessverre ikke slettet. Prøv igjen!');
    }
  };

  const deleteList = async (name: string) => {
    try {
      const findExistingList = (l: SaveList) => l.name === name;
      const listIndex = savedItems.findIndex(findExistingList);

      if (listIndex >= 0) {
        const newLists = [
          ...savedItems.slice(0, listIndex),
          ...savedItems.slice(listIndex + 1),
        ];
        await updateSavedItems(newLists);
        setStatusMessage(`Listen ${name} er slettet`);
      }
    } catch (_error) {
      setStatusMessage('Listen ble dessverre ikke slettet. Prøv igjen!');
    }
  };

  const editList = async (name: string, newListName: string) => {
    try {
      const findExistingList = (l: SaveList) => l.name === name;
      const listIndex = savedItems.findIndex(findExistingList);
      const newList = {
        ...savedItems[listIndex],
        name: newListName,
        date: new Date().getTime(),
      };

      if (listIndex >= 0) {
        const newLists = [
          ...savedItems.slice(0, listIndex),
          newList,
          ...savedItems.slice(listIndex + 1),
        ];
        await updateSavedItems(newLists);
        setStatusMessage(`${name} heter nå ${newListName}`);
      }
    } catch (_error) {
      setStatusMessage(
        'Navnet på listen ble dessverre ikke endret. Prøv igjen!',
      );
    }
  };

  return (
    <ListsContext.Provider
      value={{
        savedItems,
        statusMessage,
        saveItem,
        deleteItems,
        deleteList,
        editList,
        isLoading,
        hasError,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
};

export const useListsContext = (): ListsContextType => {
  const context = useContext(ListsContext);

  if (!context) {
    throw new Error('No saving context provided');
  }

  return context;
};
