import React, { useEffect, useState } from 'react';

import type { SaveListItem, SaveProps } from '../../../types/save-types.js';

import Image from '../teasers/teaserComponents/Image.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { useListsContext } from './ListsProvider.js';
import { sortSavedItems } from './utils.js';

export const SaveInList: React.FC<SaveProps> = ({ item, handleOverlay }) => {
  const { savedItems, saveItem, hasError, statusMessage } = useListsContext();
  const { trackClickUIElement } = usePulseContext();
  const [previousAction, setPreviousAction] = useState<string | null>(null);
  const [sorted, setSorted] = useState(savedItems);

  useEffect(() => {
    setSorted(sortSavedItems(savedItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveItem = (listName: string, listContent: SaveListItem[]) => {
    if (item) {
      saveItem(
        {
          name: listName,
          content: listContent.concat(item),
          date: new Date().getTime(),
        },
        handleOverlay,
      );
    }
  };

  const handleSaveInList = (e: React.MouseEvent<HTMLButtonElement>) => {
    const button = e.target as HTMLButtonElement;

    const listName =
      button.value || (button.parentNode as HTMLButtonElement).value;
    const list = savedItems.find((l) => l.name === listName);
    const listContent = list ? list.content : [];

    trackClickUIElement({
      object: {
        id: 'save:saved-list',
        name: 'List group in save overlay',
        elementType: 'Button',
      },
    }).finally(() => {
      setPreviousAction(listName);
      handleSaveItem(listName, listContent);
    });
  };

  return (
    <>
      {sorted.map((l, i) => {
        const thumbnail = l.content.at(-1);
        const itemSaved = l.content.some((c) => c.title === item?.title);
        const saveSuccess =
          statusMessage && !hasError && l.name === previousAction;

        if (hasError) {
          return null;
        }

        return (
          <button
            key={`list-${i}`}
            onClick={handleSaveInList}
            value={l.name}
            className={`list-button ${saveSuccess ? 'save-success' : ''}`}
          >
            {thumbnail?.imageId ? (
              <Image
                imageAsset={{ id: thumbnail.imageId }}
                height={72}
                width={72}
                title={thumbnail.title}
              />
            ) : (
              <div className="no-image-placeholder" />
            )}
            <div className="saved-list-name">{l.name}</div>
            <div className="saved-items-count">{`${l.content.length} lagret`}</div>
            {itemSaved && <div className="already-saved" />}
          </button>
        );
      })}
    </>
  );
};
