import React from 'react';

import type { GeneralChatComponentProps } from '../types.js';

import TagSuggestion from './TagSuggestion.js';
import ChatInput from './ChatInput.js';

interface ChatIntroProps extends GeneralChatComponentProps {
  setInputText: React.Dispatch<React.SetStateAction<string>>;
}

const ChatIntro: React.FC<ChatIntroProps> = ({
  setInputText,
  inputText,
  handleInputChange,
  handleKeyDown,
}) => {
  return (
    <div>
      <ChatInput
        inputText={inputText}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
      />
      <TagSuggestion setInputText={setInputText} inputText={inputText} />
    </div>
  );
};

export default ChatIntro;
