import React from 'react';

import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import { useScrollDirection } from '../../../hooks/useScrollDirection.js';

const SubscribeBar: React.FC<{ isSticky: boolean | undefined }> = ({
  isSticky,
}) => {
  const scrollDirection = useScrollDirection();

  return (
    <div
      className={`subscribe-bar advertory-subscribe-bar-anchor ${!isSticky || scrollDirection === 'up' ? 'show' : 'hide'}`}
    >
      <div className="subscribe-bar-container">
        <LinkWithPulse
          className="subscribe-bar-button fade-in"
          href="/bli-abonnent?utm_medium=navbar&utm_content=buy-button"
          id="navigation:subscribe-bar"
          name="Subscribe-bar navigation header"
        >
          Bli abonnent
        </LinkWithPulse>
      </div>
    </div>
  );
};

export default SubscribeBar;
