import React from 'react';

import ContactInfo from './ContactInfo.js';
import Disclaimer from './Disclaimer.js';

const ChatFooter: React.FC = () => {
  return (
    <div className="chat-footer">
      <Disclaimer text="Resultatet er generert ved hjelp av KI" />
      <ContactInfo />
    </div>
  );
};

export default ChatFooter;
