import { useEffect, useState } from 'react';

import type { SaveList } from '../../types/save-types.js';

import { userDataStoreApi } from '../../../../public-src/core/js/user-data-store/api.server.js';
import { DEFAULT_LIST_NAME } from '../components/saving/utils.js';
import { console } from '../../../core/system/console/index.js';

export type UseGetSavedHook = [
  SaveList[],
  (items: SaveList[]) => void,
  boolean,
  boolean,
];

const findDefaultListIndex = (data: SaveList[]): number => {
  return data.findIndex((list) => list.name === DEFAULT_LIST_NAME);
};

const addDefaultList = (data: SaveList[]): SaveList[] => {
  const defaultList: SaveList = {
    name: DEFAULT_LIST_NAME,
    content: [],
    date: new Date().getTime(),
  };

  return [defaultList, ...data];
};

const moveDefaultListToFront = (
  data: SaveList[],
  defaultListIndex: number,
): SaveList[] => {
  const listWithoutDefault = data.filter((_, i) => i !== defaultListIndex);

  return [data[defaultListIndex], ...listWithoutDefault];
};

const updateDataWithDefaultList = (
  lists: SaveList[],
  setSavedItems: (lists: SaveList[]) => void,
) => {
  const defaultListIndex = findDefaultListIndex(lists);

  if (defaultListIndex === 0) {
    setSavedItems(lists);

    return;
  }

  let modifiedLists: SaveList[] = [];

  if (defaultListIndex !== -1) {
    modifiedLists = moveDefaultListToFront(lists, defaultListIndex);
  } else {
    modifiedLists = addDefaultList(lists);
  }

  setSavedItems(modifiedLists);
};

export const useGetSaved = (): UseGetSavedHook => {
  const [savedItems, setSavedItems] = useState<SaveList[]>([]);
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response =
          await userDataStoreApi.getUserData<SaveList[]>('vink-lists');
        const data = response?.value ?? [];

        updateDataWithDefaultList(data, setSavedItems);
      } catch (error) {
        console.error('Error fetching or processing saved items:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return [savedItems, setSavedItems, isLoading, hasError];
};
