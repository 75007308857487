import React from 'react';

import type { SubmitButtonProps } from '../types.js';
import type { FrapiTeaserItemSized } from '../../../../types/frapi-types.js';

import RecommendationList from './RecommendationList.js';
import ChatSubmitButton from './ChatSubmitButton.js';

interface MessageProps extends SubmitButtonProps {
  text: string;
  recommendations?: FrapiTeaserItemSized[];
}

const Message: React.FC<MessageProps> = ({
  text,
  sendMessage,
  inputText,
  buttonLabel,
  recommendations,
}) => {
  return (
    <div className="messages">
      <div className="messages__container">
        <div className="message">
          <p className="answer">{text}</p>
          <ChatSubmitButton
            sendMessage={sendMessage}
            inputText={inputText}
            buttonLabel={buttonLabel}
          />
          <RecommendationList recommendations={recommendations} />
        </div>
      </div>
    </div>
  );
};

export default Message;
